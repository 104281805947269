<template>
  <div>
    <vuex-breadcrumb></vuex-breadcrumb>
    <profile-show :id="id"></profile-show>
    <actor-role-index
      :filtering="actor_filter"
      :refresh="refresh"
      @refreshed="refreshed"
    >
    </actor-role-index>
  </div>
</template>

<script>
import VuexBreadcrumb from './VuexBreadcrumb.vue'
import ProfileShow from './ProfileShow.vue'
import ActorRoleIndex from './ActorRoleIndex.vue'

export default {
  components: {
    VuexBreadcrumb,
    ProfileShow,
    ActorRoleIndex
  },
  props: {
    id: String
  },
  data() {
    return {
      actor_filter: {
        profile_ids: this.id
      },
      refresh: false
    }
  },
  methods: {
    refreshed() {
      this.refresh = false
    }
  }
}
</script>